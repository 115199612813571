import React, { Component } from "react"
import { graphql } from "gatsby"
import Footer from "../components/Footer"

import App from "../components/App"
import CTA from "../components/CTA"
import SEO from "../components/seo"
import Hero from "../components/Hero"

import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import CardDeck from "react-bootstrap/CardDeck"
import ReactPaginate from "react-paginate"

class Blog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      offset: 0,
    }
  }

  loadPostsFromServer() {
    const PER_PAGE = 6

    const getPaginatedItems = (items, offset) => {
      return items.slice(offset, offset + PER_PAGE)
    }

    // for simulating post data
		/*
    const collection = (arr, max) => {
      for (var array = [], i = 0; i < max; i++) {
        array.push(arr[0])
        array.push(arr[0])
        array.push(arr[0])
        array.push(arr[0])
        array.push(arr[0])
        array.push(arr[0])
        array.push(arr[1])
        array.push(arr[1])
        array.push(arr[1])
        array.push(arr[2])
        array.push(arr[2])
        array.push(arr[2])
        array.push(arr[3])
        array.push(arr[3])
        array.push(arr[3])
        array.push(arr[3])
        array.push(arr[3])
        array.push(arr[3])
      }
      return array
    }
		const posts = collection(this.props.data.allContentfulBlogPost.edges, 25)
		*/

    const posts = this.props.data.allContentfulBlogPost.edges

    var items = posts

    var offset = this.state.offset ? parseInt(this.state.offset, 10) : 0

    this.setState({
      data: getPaginatedItems(items, offset),
      pageCount: Math.ceil(items.length / PER_PAGE),
    })
  }

  handlePageClick = data => {
    let selected = data.selected
    let offset = Math.ceil(selected * 6)

    this.setState({ offset: offset }, () => {
      this.loadPostsFromServer()
    })
  }

  componentDidMount() {
    this.loadPostsFromServer()
  }

  render() {
    const { cta, hero } = this.props.data.allContentfulBlogMain.edges[0].node
    const navbarData = this.props.data.allContentfulNavbar.edges[0].node
    const footerData = this.props.data.allContentfulFooter.edges[0].node

    const postImageStyle = url => {
      return {
        backgroundImage: `url(${url})`,
      }
    }

    const postURL = url => {
      const root = typeof document !== `undefined` ? document.location.href : ""
      if (!root.includes("/blog")) {
        return root + "blog" + url
      } else {
        return root + url
      }
    }

    const cardCatBGStyle = color => {
      return { backgroundColor: color }
    }

    const cardCatLabelStyle = color => {
      return { color: color }
    }

    return (
      <App navbar={navbarData}>
        <SEO title="Blog" />
        <Hero navbar={navbarData} hero={hero} />
        <div className="content blog-content">
          <Container className="content-section pt-lg pb-lg">
            <CardDeck className="post-cards">
              {this.state.data.map((edge, key) => (
                <Card key={key} className="post-card">
                  <a
                    className="card-image"
										href={postURL(edge.node.slug)}
                    style={postImageStyle(edge.node.image.file.url)}
                  ></a>
                  <Card.Body>
                    <span className="card-category">
                      <span
                        className="card-category-bg"
                        style={cardCatBGStyle(edge.node.category.color)}
                      ></span>
                      <span
                        className="card-category-label"
                        style={cardCatLabelStyle(edge.node.category.color)}
                      >
                        {edge.node.category.title}
                      </span>
                    </span>
                    <h4 className="card-title">
                      <a href={postURL(edge.node.slug)}>{edge.node.title}</a>
                    </h4>
                    <p className="card-text">{edge.node.excerpt}</p>
                    <a href={postURL(edge.node.slug)} className="card-readmore">
                      Read more &rarr;
                    </a>
                  </Card.Body>
                </Card>
              ))}
            </CardDeck>
            {this.state.data.length > 0 &&
              <ReactPaginate
              previousLabel={"← Previous"}
              nextLabel={"Next →"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />}
          </Container>
        </div>
        <CTA cta={cta} />
        <Footer footer={footerData} />
      </App>
    )
  }
}

export default Blog

export const blogQuery = graphql`
  query blogQuery {
    allContentfulBlogPost(sort: {order: DESC, fields: createdAt}) {
      edges {
        node {
          image {
            file {
              url
            }
          }
          title
          slug
          excerpt
          category {
            title
            color
          }
        }
      }
    }
    allContentfulBlogMain {
      edges {
        node {
          description
          title
          cta {
            title
            buttonCTA {
              link
              label
            }
          }
          hero {
            title
            label
            backgroundImage {
              file {
                url
              }
            }
            backgroundVideo {
              backgroundVideo
            }
            content {
              json
            }
          }
        }
      }
    }
    allContentfulNavbar {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          id
          menus {
            title
            titleLink
            links {
              url
              title
            }
          }
          colophon
        }
      }
    }
  }
`
